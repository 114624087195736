import { Alert, Card, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from "react-i18next";
import { Link, Routes, useNavigate } from 'react-router-dom';
import logo_light from '../../assets/images/logo_light.png';
import logo_cpu_light from '../../assets/images/logo_cpu_light.png';
import './ApplicantCard.css';
import Barcode from 'react-barcode';
import ULButton from '../../components/ULButton';
import ApplicationContext from '../../context/ApplicationContext';
import * as APIServices from "../../services/rest/APIServices";
import { protectEmail, SCROLLABLE_AREA_NAME } from '../../utils/CommonFunctions';
import Loading from '../../components/Loading';
import * as ROUTES from "../../routes/routes";

export default function ApplicantsCard() {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { applicationID } = React.useContext(ApplicationContext);
  const [errMessages, setErrMessages] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [disclaimersList, setDisclaimersList] = React.useState([]);

  const [personalInfo, setPersonalInfo] = React.useState({
    email: "",
    uuid: "",

    callName: "",
    documentType: "",
    documentNumber: "",
    applicantCode: "",
    firstSurname: "",
    secondSurname: "",
    names: "",
    session: "",
    career: "",
    applicantCode: "",
    cpuCode: "",
    classroom: "",
    desk: "",
    modality: "",
    isCpu: false,
  });
  const [photo, setPhoto] = React.useState("");

  const clearApplication = () => {
    sessionStorage.clear();
    window.location.href = '/';
  }

  const getApplicantDetails = () => {
    setIsLoading(true);
    APIServices.fetchCard(applicationID)
      .then((response) => {
        let applicantData = response.data.data;
        setPersonalInfo({
          email: applicantData.email ?? "",
          uuid: applicantData.uuid ?? "",

          callName: applicantData.callName ?? "--",
          documentType: applicantData.documentType ?? "--",
          documentNumber: applicantData.documentNumber ?? "--",
          applicantCode: applicantData.applicantCode ?? "--",
          firstSurname: applicantData.firstSurname ?? "--",
          secondSurname: applicantData.secondSurname ?? "--",
          names: applicantData.names ?? "--",
          session: applicantData.session ?? "--",
          career: applicantData.career ?? "--",
          applicantCode: applicantData.applicantCode ?? "--",
          cpuCode: applicantData.cpuCode ?? "--",
          classroom: applicantData.classroom ?? "--",
          desk: applicantData.desk ?? "--",
          modality: applicantData.modality ?? "--",
          isCpu: applicantData.isCpu ?? false,
        })
        APIServices.getCardPhoto(applicationID, applicantData.uuid)
          .then((response) => {
            const imageContentType = 'image/jpeg';
            const imageBinary = new Blob([response.data], { type: imageContentType });
            const imageUrl = URL.createObjectURL(imageBinary);
            setPhoto(imageUrl);
          })
          .catch(error => console.error('Error al obtener la imagen:', error));
      })
      .catch((error) => {
        setErrMessages(error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }])
        const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
        scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
        window.scrollTo({ top: 0, behavior: 'smooth' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const listDisclaimers = () => {
    APIServices.listDisclaimersQuickGuide(applicationID)
      .then((response) => {
        const disclaimers = response.data.data?.list;
        setDisclaimersList(disclaimers);
      })
      .catch((error) => {
        setErrMessages(error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }])
      })
  }

  React.useEffect(() => {
    getApplicantDetails();
    listDisclaimers();
  }, [])

  const handleDownload = (e) => {
    e.target.disabled = true;
    const loadingSpinner = e.target.querySelector('span');
    loadingSpinner.classList.remove('hidden');
    setErrMessages([]);
    APIServices.downloadCard(applicationID)
      .then((response) => {
        const fileContentType = response.headers['content-type'];
        const cardFilename = response.headers.ulima_filename;
        const cardBinary = new Blob([response.data], { type: fileContentType });
        const cardUrl = URL.createObjectURL(cardBinary);
        const dlink = document.createElement("a");
        dlink.href = cardUrl;
        dlink.download = cardFilename;
        dlink.target = "_blank";
        dlink.style.display = "none";
        document.body.appendChild(dlink);
        dlink.click();
        URL.revokeObjectURL(cardUrl);
        document.body.removeChild(dlink);
      })
      .catch((error) => {
        setErrMessages(error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }])
        const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
        scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
        window.scrollTo({ top: 0, behavior: 'smooth' });
      })
      .finally(() => {
        e.target.disabled = false;
        loadingSpinner.classList.add('hidden');
      })
  }

  return (
    <>
      {
        isLoading ?
          <>
            <Loading />
          </> :
          <>
            <Grid container direction="column" spacing={2}>
              {
                errMessages?.length ?
                  <Grid item xs={12}>
                    <Alert role="alert" severity="error">
                      {
                        errMessages.map((err, index) => (
                          <Typography key={index}> {err.code?.length ? `[${err.code}]` : ""} {err.message}</Typography>
                        ))
                      }
                    </Alert>
                  </Grid>
                  : <></>
              }
              <Grid item xs={12}>
                <Typography variant="h5" component="h4" style={{ fontWeight: 400, fontSize: '16px' }}>
                  {t('applicants-card.title')}
                </Typography>
                <Typography variant="h5" component="h4" style={{ fontWeight: 500, fontSize: '20px' }}>
                  {t('applicants-card.applicants-card')}
                </Typography>
                <Typography variant="h5" component="h4" style={{ fontWeight: 400, fontSize: '14px' }}>
                  {t('applicants-card.description-applicants-card')}
                </Typography>
              </Grid>
              <Grid item container xs={12}>
                <Card variant='outlined' sx={{ marginLeft: 'auto', marginRight: 'auto' }}>
                  <Grid
                    spacing={2}
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {
                      personalInfo.isCpu ?
                        <>
                          <img
                            src={logo_cpu_light}
                            height={35}
                            alt={t('top-bar.ulima-logo-alt')}
                            loading="lazy"
                            style={{ margin: '1rem' }}
                          />
                        </> :
                        <>
                          <img
                            src={logo_light}
                            height={35}
                            alt={t('top-bar.ulima-logo-alt')}
                            loading="lazy"
                            style={{ margin: '1rem' }}
                          />
                        </>
                    }
                  </Grid>
                  <Grid
                    spacing={2}
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    sx={{ marginBottom: '1rem' }}
                  >
                    <Typography variant="h4" component="h4" style={{ fontWeight: 600, fontSize: '1.25rem', margin: '1rem' }}>
                      {`${personalInfo.callName}`}
                    </Typography>
                  </Grid>
                  <Grid
                    spacing={2}
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    sx={{ marginBottom: '1rem' }}
                  >
                    <img
                      src={photo}
                      alt={t('applicants-card.applicant-photo')}
                      loading="lazy"
                      className='applicant-photo'
                    />
                  </Grid>
                  <Grid
                    spacing={2}
                    item
                    xs={12}
                    sx={{ marginBottom: '1rem' }}
                  >
                    {
                      personalInfo.isCpu ?
                        <>
                          <div className='applicant-data-form'>
                            <Typography className='label' variant="h5" component="h4" style={{ fontWeight: 600, fontSize: '14px' }}>
                              {t('applicants-card.code')}
                            </Typography>
                            <Typography variant="h5" component="h4" style={{ fontWeight: 400, fontSize: '14px' }}>
                              {personalInfo.cpuCode}
                            </Typography>
                          </div>
                          <div className='applicant-data-form'>
                            <Typography className='label' variant="h5" component="h4" style={{ fontWeight: 600, fontSize: '14px' }}>
                              {t('applicants-card.first-surname')}
                            </Typography>
                            <Typography variant="h5" component="h4" style={{ fontWeight: 400, fontSize: '14px' }}>
                              {personalInfo.firstSurname}
                            </Typography>
                          </div>
                          <div className='applicant-data-form'>
                            <Typography className='label' variant="h5" component="h4" style={{ fontWeight: 600, fontSize: '14px' }}>
                              {t('applicants-card.second-surname')}
                            </Typography>
                            <Typography variant="h5" component="h4" style={{ fontWeight: 400, fontSize: '14px' }}>
                              {personalInfo.secondSurname}
                            </Typography>
                          </div>
                          <div className='applicant-data-form'>
                            <Typography className='label' variant="h5" component="h4" style={{ fontWeight: 600, fontSize: '14px' }}>
                              {t('applicants-card.name')}
                            </Typography>
                            <Typography variant="h5" component="h4" style={{ fontWeight: 400, fontSize: '14px' }}>
                              {personalInfo.names}
                            </Typography>
                          </div>
                          <div className='applicant-data-form'>
                            <Typography className='label' variant="h5" component="h4" style={{ fontWeight: 600, fontSize: '14px' }}>
                              {t('applicants-card.session')}
                            </Typography>
                            <Typography variant="h5" component="h4" style={{ fontWeight: 400, fontSize: '14px' }}>
                              {personalInfo.session}
                            </Typography>
                          </div>
                          <div className='applicant-data-form'>
                            <Typography className='label' variant="h5" component="h4" style={{ fontWeight: 600, fontSize: '14px' }}>
                              {t('applicants-card.career')}
                            </Typography>
                            <Typography variant="h5" component="h4" style={{ fontWeight: 400, fontSize: '14px' }}>
                              {personalInfo.career}
                            </Typography>
                          </div>
                          <div className='applicant-data-form'>
                            <Typography className='label' variant="h5" component="h4" style={{ fontWeight: 600, fontSize: '14px' }}>
                              {t('applicants-card.applicant')}
                            </Typography>
                            <Typography variant="h5" component="h4" style={{ fontWeight: 400, fontSize: '14px' }}>
                              {personalInfo.applicantCode}
                            </Typography>
                          </div>
                          <div className='applicant-data-form'>
                            <Typography className='label' variant="h5" component="h4" style={{ fontWeight: 600, fontSize: '14px' }}>
                              {personalInfo.documentType}
                            </Typography>
                            <Typography variant="h5" component="h4" style={{ fontWeight: 400, fontSize: '14px' }}>
                              {personalInfo.documentNumber}
                            </Typography>
                          </div>

                        </> :
                        <>
                          <div className='applicant-data-form'>
                            <Typography className='label' variant="h5" component="h4" style={{ fontWeight: 600, fontSize: '14px' }}>
                              {t('applicants-card.applicant')}
                            </Typography>
                            <Typography variant="h5" component="h4" style={{ fontWeight: 400, fontSize: '14px' }}>
                              {personalInfo.applicantCode}
                            </Typography>
                          </div>
                          <div className='applicant-data-form'>
                            <Typography className='label' variant="h5" component="h4" style={{ fontWeight: 600, fontSize: '14px' }}>
                              {t('applicants-card.first-surname')}
                            </Typography>
                            <Typography variant="h5" component="h4" style={{ fontWeight: 400, fontSize: '14px' }}>
                              {personalInfo.firstSurname}
                            </Typography>
                          </div>
                          <div className='applicant-data-form'>
                            <Typography className='label' variant="h5" component="h4" style={{ fontWeight: 600, fontSize: '14px' }}>
                              {t('applicants-card.second-surname')}
                            </Typography>
                            <Typography variant="h5" component="h4" style={{ fontWeight: 400, fontSize: '14px' }}>
                              {personalInfo.secondSurname}
                            </Typography>
                          </div>
                          <div className='applicant-data-form'>
                            <Typography className='label' variant="h5" component="h4" style={{ fontWeight: 600, fontSize: '14px' }}>
                              {t('applicants-card.name')}
                            </Typography>
                            <Typography variant="h5" component="h4" style={{ fontWeight: 400, fontSize: '14px' }}>
                              {personalInfo.names}
                            </Typography>
                          </div>
                          <div className='applicant-data-form'>
                            <Typography className='label' variant="h5" component="h4" style={{ fontWeight: 600, fontSize: '14px' }}>
                              {t('applicants-card.classroom')}
                            </Typography>
                            <Typography variant="h5" component="h4" style={{ fontWeight: 400, fontSize: '14px' }}>
                              {personalInfo.classroom}
                            </Typography>
                          </div>
                          <div className='applicant-data-form'>
                            <Typography className='label' variant="h5" component="h4" style={{ fontWeight: 600, fontSize: '14px' }}>
                              {t('applicants-card.desk')}
                            </Typography>
                            <Typography variant="h5" component="h4" style={{ fontWeight: 400, fontSize: '14px' }}>
                              {personalInfo.desk}
                            </Typography>
                          </div>
                          <div className='applicant-data-form'>
                            <Typography className='label' variant="h5" component="h4" style={{ fontWeight: 600, fontSize: '14px' }}>
                              {t('applicants-card.modality')}
                            </Typography>
                            <Typography variant="h5" component="h4" style={{ fontWeight: 400, fontSize: '14px' }}>
                              {personalInfo.modality}
                            </Typography>
                          </div>
                          <div className='applicant-data-form'>
                            <Typography className='label' variant="h5" component="h4" style={{ fontWeight: 600, fontSize: '14px' }}>
                              {t('applicants-card.career')}
                            </Typography>
                            <Typography variant="h5" component="h4" style={{ fontWeight: 400, fontSize: '14px' }}>
                              {personalInfo.career}
                            </Typography>
                          </div>
                          <div className='applicant-data-form'>
                            <Typography className='label' variant="h5" component="h4" style={{ fontWeight: 600, fontSize: '14px' }}>
                              {personalInfo.documentType}
                            </Typography>
                            <Typography variant="h5" component="h4" style={{ fontWeight: 400, fontSize: '14px' }}>
                              {personalInfo.documentNumber}
                            </Typography>
                          </div>
                        </>
                    }

                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <p>
                  {t('applicants-card.quick-guide')}
                </p>
                <ul>
                  {
                    disclaimersList?.map((disclaimer) => (<React.Fragment>
                      <li><Link to={`${ROUTES.DISCLAIMERS_BASE}/${disclaimer.uuid}`} target='_blank' style={{ color: '#ff5117' }}>{disclaimer.name}</Link></li>
                    </React.Fragment>))
                  }
                </ul>
              </Grid>
              <Grid item xs={12}>
                <ULButton onClick={handleDownload} fullWidth>
                  {t('applicants-card.buttons.download-card')}
                </ULButton>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" component="h5" style={{ fontWeight: 400, fontSize: '12px' }}>
                  {t('applicants-card.footer-description', { email: protectEmail(personalInfo.email ?? "") })}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ULButton variant="outlined" onClick={clearApplication}>
                  {t('buttons.exit')}
                </ULButton>
              </Grid>
            </Grid>
          </>
      }
    </>
  );
}