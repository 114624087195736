import http from "./HTTPService";
import { API_APPLICATION_CONTINUE, API_CONFIRM_PROVIDED_INFORMATION, API_DELETE_FILE_DC, API_DELETE_FILE_NF, API_DELETE_MODALITY_AND_CAREER_UNIVERSITY_FILE_NF, API_DOWNLOAD_DISCLAIMER, API_DOWNLOAD_FILE_DC, API_DOWNLOAD_FILE_NF, API_DOWNLOAD_MODALITY_AND_CAREER_UNIVERSITY_FILE_NF, API_DOWNLOAD_TEMPLATE_DC, API_DUMMY_PAYMENT, API_FETCH_APPLICANT_DOCUMENT, API_FETCH_APPLICANT_INFO, API_FETCH_APPLICANT_INFO_REVIEW, API_FETCH_APPLICATION_SUMMARY_INFO, API_FETCH_EDUCATION_INFO_FIFTH_GRADE, API_FETCH_EDUCATION_INFO_FOURTH_GRADE, API_FETCH_EDUCATION_INFO_THIRD_GRADE, API_FETCH_MODALITY_AND_CAREER_INFO, API_GEOLOCATION_AUTOCOMPLETE_LIST, API_GEOLOCATION_INTERNATIONAL_AUTOCOMPLETE_LIST, API_GET_APPLICATIONS_LIST, API_GET_CAREER_LIST, API_GET_DOCUMENT_TYPE_LIST, API_GET_MODALITY_LIST, API_GET_REQUEST_CODE_TYPE_LIST, API_GET_SCHOOL_TYPE_LIST, API_GET_UNIVERSITY_TYPE_LIST, API_LIST_DISCLAIMERS, API_LIST_FILES_DC, API_LIST_FILES_NF, API_LIST_FILES_VERIFICATION_DC, API_LIST_FILES_VERIFICATION_NF, API_LIST_MODALITY_AND_CAREER_UNIVERSITY_FILES_NF, API_LIST_MODALITY_AND_CAREER_UNIVERSITY_FILES_VERIFICATION_NF, API_RECOVER_OMR, API_REQUEST_VERIFICATION_CODE, API_SAVE_APPLICANT_DOCUMENT, API_SAVE_APPLICANT_INFO, API_SAVE_EDUCATION_INFO_FIFTH_GRADE, API_SAVE_EDUCATION_INFO_FOURTH_GRADE, API_SAVE_EDUCATION_INFO_THIRD_GRADE, API_SAVE_MODALITY_AND_CAREER_INFO, API_SAVE_TERMS_AGREEMENT, API_SCHOOL_AUTOCOMPLETE_LIST, API_UNIVERSITY_AUTOCOMPLETE_LIST, API_UNIVERSITY_INTERNATIONAL_AUTOCOMPLETE_LIST, API_UPDATE_APPLICANT_INFO, API_UPDATE_REQUIREMENT_DC, API_UPDATE_STEP, API_UPLOAD_FILE_DC, API_UPLOAD_FILE_NF, API_UPLOAD_MODALITY_AND_CAREER_UNIVERSITY_FILE_NF, API_VALIDATE_VERIFICATION_CODE, API_VERIFICATION_FINISH_CORRECTION, API_CARD_EXPORT, API_CARD_FETCH, API_CARD_PHOTO_GET, API_CONFIRM_APPLICANT_DATA, API_FETCH_PAYMENT_INFO, API_GET_HEADER_DATA, API_LIST_DISCLAIMERS_QUICK_GUIDE, API_POST_HAS_BACCALAUREATE, API_LIST_DISCLAIMERS_TERMS } from "./APIEndpoints";

export const getApplicationsList = async () => {
    return http.get(API_GET_APPLICATIONS_LIST);
}

export const getDocumentTypeList = async () => {
    return http.get(API_GET_DOCUMENT_TYPE_LIST);
}

export const saveApplicantDocument = async (data) => {
    return http.post(API_SAVE_APPLICANT_DOCUMENT, data)
}

export const fetchApplicantDocument = async (applicationID) => {
    return http.get(`${API_FETCH_APPLICANT_DOCUMENT}?applicationId=${applicationID}`);
}

export const fetchApplicantInfoReview = async (applicationID) => {
    return http.get(`${API_FETCH_APPLICANT_INFO_REVIEW}?applicationId=${applicationID}`);
}

export const saveApplicantInfo = async (data) => {
    return http.post(API_SAVE_APPLICANT_INFO, data)
}

export const fetchApplicantInfo = async (applicationID) => {
    return http.get(`${API_FETCH_APPLICANT_INFO}?applicationId=${applicationID}`);
}

export const fetchApplicationSummaryInfo = async (applicationID) => {
    return http.get(`${API_FETCH_APPLICATION_SUMMARY_INFO}?applicationId=${applicationID}`);
}

export const requestVerificationCode = async (data) => {
    return http.post(API_REQUEST_VERIFICATION_CODE, data)
}

export const validateVerificationCode = async (data) => {
    return http.post(API_VALIDATE_VERIFICATION_CODE, data)
}

export const getRequestCodeTypeList = async () => {
    return http.get(API_GET_REQUEST_CODE_TYPE_LIST);
}

export const getSchoolTypeList = async () => {
    return http.get(API_GET_SCHOOL_TYPE_LIST);
}

export const getSchoolAutocompleteList = async (search, geolocation, cancelTokenSource) => {
    if (geolocation.length)
        return http.get(`${API_SCHOOL_AUTOCOMPLETE_LIST}?search=${search}&geolocation=${geolocation}`, {
            cancelToken: cancelTokenSource.token
        });
    return http.get(`${API_SCHOOL_AUTOCOMPLETE_LIST}?search=${search}`, {
        cancelToken: cancelTokenSource.token
    });
}

export const getGeolocationAutocompleteList = async (search, cancelTokenSource) => {
    return http.get(`${API_GEOLOCATION_AUTOCOMPLETE_LIST}?search=${search}`, {
        cancelToken: cancelTokenSource.token
    });
}

export const saveEducationInfo = async (grade, data) => {
    if (grade === "fifth")
        return http.post(API_SAVE_EDUCATION_INFO_FIFTH_GRADE, data)
    if (grade === "fourth")
        return http.post(API_SAVE_EDUCATION_INFO_FOURTH_GRADE, data)
    return http.post(API_SAVE_EDUCATION_INFO_THIRD_GRADE, data)
}

export const fetchEducationInfo = async (grade, applicationID) => {
    if (grade === "fifth")
        return http.get(`${API_FETCH_EDUCATION_INFO_FIFTH_GRADE}?applicationId=${applicationID}`);
    if (grade === "fourth")
        return http.get(`${API_FETCH_EDUCATION_INFO_FOURTH_GRADE}?applicationId=${applicationID}`);
    return http.get(`${API_FETCH_EDUCATION_INFO_THIRD_GRADE}?applicationId=${applicationID}`);
}

export const hasBaccalaureate = async (applicationID, schoolID) => {
    return http.post(`${API_POST_HAS_BACCALAUREATE}`, {
        applicationId: applicationID,
        schoolId: schoolID,
    });
}

export const getModalityList = async (applicationID) => {
    return http.get(`${API_GET_MODALITY_LIST}?applicationId=${applicationID}`);
}

export const getCareerList = async (applicationID) => {
    return http.get(`${API_GET_CAREER_LIST}?applicationId=${applicationID}`);
}

export const fetchModalityAndCareerInfo = async (applicationID) => {
    return http.get(`${API_FETCH_MODALITY_AND_CAREER_INFO}?applicationId=${applicationID}`);
}

export const saveModalityAndCareerInfo = async (data) => {
    return http.post(API_SAVE_MODALITY_AND_CAREER_INFO, data)
}

export const uploadFileNF = async (data, options) => {
    return http.post(API_UPLOAD_FILE_NF, data, options)
}

export const deleteFileNF = async (data) => {
    return http.post(API_DELETE_FILE_NF, data)
}

export const downloadFileNF = async (applicationID, applicantSchoolID, uuid) => {
    return http.get(`${API_DOWNLOAD_FILE_NF}?applicationId=${applicationID}&applicantSchoolId=${applicantSchoolID}&uuid=${uuid}`, {
        responseType: 'arraybuffer'
    });
}

export const listFilesNF = async (applicationID, applicantSchoolID) => {
    return http.get(`${API_LIST_FILES_NF}?applicationId=${applicationID}&applicantSchoolId=${applicantSchoolID}`);
}


export const uploadFileDC = async (data, options) => {
    return http.post(API_UPLOAD_FILE_DC, data, options)
}

export const deleteFileDC = async (data) => {
    return http.post(API_DELETE_FILE_DC, data)
}

export const downloadFileDC = async (applicationID, requirementID, uuid) => {
    return http.get(`${API_DOWNLOAD_FILE_DC}?applicationId=${applicationID}&requirementId=${requirementID}&uuid=${uuid}`, {
        responseType: 'arraybuffer'
    });
}

export const downloadTemplateDC = async (applicationID, requirementID, uuid) => {
    return http.get(`${API_DOWNLOAD_TEMPLATE_DC}?applicationId=${applicationID}&requirementId=${requirementID}&uuid=${uuid}`, {
        responseType: 'arraybuffer'
    });
}

export const listFilesDC = async (applicationID) => {
    return http.get(`${API_LIST_FILES_DC}?applicationId=${applicationID}`);
}


export const updateStep = async (applicationID, step) => {
    return http.post(`${API_UPDATE_STEP}`, {
        applicationId: applicationID,
        step: step,
    });
}

export const saveTermsAgreement = async (applicationID) => {
    const data = {
        applicationId: applicationID,
        declarationAccepted: true,
    }
    return http.post(API_SAVE_TERMS_AGREEMENT, data)
}

export const updateRequirementDC = async (applicationID, requirementID, uuid, valid) => {
    const data = {
        applicationId: applicationID,
        requirementId: requirementID,
        uuid: uuid,
        valid: valid ?? false,
    }
    return http.post(API_UPDATE_REQUIREMENT_DC, data)
}

export const confirmProvidedInformation = async (applicationID) => {
    const data = {
        applicationId: applicationID,
    }
    return http.post(API_CONFIRM_PROVIDED_INFORMATION, data)
}

export const confirmApplicantData = async (applicationID) => {
    const data = {
        applicationId: applicationID,
    }
    return http.post(API_CONFIRM_APPLICANT_DATA, data)
}

export const continueApplication = async (data) => {
    return http.post(API_APPLICATION_CONTINUE, data)
}

export const downloadDisclaimerFile = async (uuid) => {
    return http.get(`${API_DOWNLOAD_DISCLAIMER}?uuid=${uuid}`, {
        responseType: 'arraybuffer'
    });
}

export const recoverOMR = async (data) => {
    return http.post(API_RECOVER_OMR, data)
}

export const getUniversityAutocompleteList = async (isLocal, universityTypeId, search, geolocation, cancelTokenSource) => {
    if (isLocal) {
        if (geolocation.length)
            return http.get(`${API_UNIVERSITY_AUTOCOMPLETE_LIST}?search=${search}&geolocation=${geolocation}&universityType=${universityTypeId}`, {
                cancelToken: cancelTokenSource.token
            });
        return http.get(`${API_UNIVERSITY_AUTOCOMPLETE_LIST}?search=${search}&universityType=${universityTypeId}`, {
            cancelToken: cancelTokenSource.token
        });
    }
    else {
        if (geolocation.length)
            return http.get(`${API_UNIVERSITY_INTERNATIONAL_AUTOCOMPLETE_LIST}?search=${search}&geolocation=${geolocation}&universityType=${universityTypeId}`, {
                cancelToken: cancelTokenSource.token
            });
        return http.get(`${API_UNIVERSITY_INTERNATIONAL_AUTOCOMPLETE_LIST}?search=${search}&universityType=${universityTypeId}`, {
            cancelToken: cancelTokenSource.token
        });
    }
}

export const getGeolocationInternationalAutocompleteList = async (isLocal, search, cancelTokenSource) => {
    if (isLocal) {
        return http.get(`${API_GEOLOCATION_AUTOCOMPLETE_LIST}?search=${search}`, {
            cancelToken: cancelTokenSource.token
        });
    }
    return http.get(`${API_GEOLOCATION_INTERNATIONAL_AUTOCOMPLETE_LIST}?search=${search}`, {
        cancelToken: cancelTokenSource.token
    });
}


export const getUniversityTypeList = async () => {
    return http.get(API_GET_UNIVERSITY_TYPE_LIST);
}

export const listUniversityFilesNF = async (applicationID) => {
    return http.get(`${API_LIST_MODALITY_AND_CAREER_UNIVERSITY_FILES_NF}?applicationId=${applicationID}`);
}

export const uploadUniversityFileNF = async (data, options) => {
    return http.post(API_UPLOAD_MODALITY_AND_CAREER_UNIVERSITY_FILE_NF, data, options)
}

export const deleteUniversityFileNF = async (data) => {
    return http.post(API_DELETE_MODALITY_AND_CAREER_UNIVERSITY_FILE_NF, data)
}

export const downloadUniversityFileNF = async (applicationID, uuid) => {
    return http.get(`${API_DOWNLOAD_MODALITY_AND_CAREER_UNIVERSITY_FILE_NF}?applicationId=${applicationID}&uuid=${uuid}`, {
        responseType: 'arraybuffer'
    });
}

export const listFilesVerificationDC = async (applicationID) => {
    return http.get(`${API_LIST_FILES_VERIFICATION_DC}?applicationId=${applicationID}`);
}

export const listFilesVerificationNF = async (applicationID, applicantSchoolID) => {
    return http.get(`${API_LIST_FILES_VERIFICATION_NF}?applicationId=${applicationID}&applicantSchoolId=${applicantSchoolID}`);
}

export const listUniversityFilesVerificationNF = async (applicationID) => {
    return http.get(`${API_LIST_MODALITY_AND_CAREER_UNIVERSITY_FILES_VERIFICATION_NF}?applicationId=${applicationID}`);
}

export const verificationFinishCorrection = async (applicationID) => {
    return http.post(`${API_VERIFICATION_FINISH_CORRECTION}`, {
        applicationId: applicationID
    });
}


export const dummyPayment = async (applicationID, paid) => {
    // const data = {
    //     applicationId: applicationID,
    //     paid: paid,
    // }
    // return http.post(API_DUMMY_PAYMENT, data)
    return http.post(`${API_DUMMY_PAYMENT}`, {
        applicationId: applicationID,
        paid: paid
    })
}

export const updateApplicantInfo = async (data) => {
    return http.post(API_UPDATE_APPLICANT_INFO, data)
}

export const listDisclaimers = async (applicationID) => {
    return http.get(`${API_LIST_DISCLAIMERS}?applicationId=${applicationID}`);
}

export const listDisclaimersQuickGuide = async (applicationID) => {
    return http.get(`${API_LIST_DISCLAIMERS_QUICK_GUIDE}?applicationId=${applicationID}`);
}

export const listDisclaimersTerms = async () => {
    return http.get(`${API_LIST_DISCLAIMERS_TERMS}`);
}

export const downloadCard = async (applicationID) => {
    return http.get(`${API_CARD_EXPORT}?applicationId=${applicationID}`, {
        responseType: 'arraybuffer'
    });
}

export const fetchCard = async (applicationID) => {
    return http.get(`${API_CARD_FETCH}?applicationId=${applicationID}`);
}

export const getCardPhoto = async (applicationID, uuid) => {
    return http.get(`${API_CARD_PHOTO_GET}?applicationId=${applicationID}&uuid=${uuid}`, {
        responseType: 'arraybuffer'
    });
}

export const fetchPaymentInfo = async (applicationID) => {
    return http.get(`${API_FETCH_PAYMENT_INFO}?applicationId=${applicationID}`);
}

export const getHeaderData = async (applicationID) => {
    return http.get(`${API_GET_HEADER_DATA}?applicationId=${applicationID}`);
}