import * as React from 'react';
import { Alert, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as ROUTES from "../../routes/routes";
import TextField from '@mui/material/TextField';
import { useTranslation } from "react-i18next";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import StudentPersonalDataDisability from "./StudentPersonalDataDisability";
import { FormControl, Radio, RadioGroup } from '@mui/material';
import { useContext } from 'react';
import * as APIServices from "../../services/rest/APIServices";
import ApplicationContext from '../../context/ApplicationContext';
import { useEffect } from 'react';
import Loading from '../../components/Loading';
import { handlePreventInvalidPhoneNumbers, isValidEmail } from '../../utils/Validations';
import PersonalInformationContext from '../../context/PersonalInformationContext';
import ULButton from '../../components/ULButton';
import { SCROLLABLE_AREA_NAME, canUserEditInformation } from '../../utils/CommonFunctions';

const StudentPersonalData = (props) => {
  const { applicationID, verificationCodeValidated, correcting } = useContext(ApplicationContext);
  const { name, setName,
    firstLastName, setFirstLastName,
    secondLastName, setSecondLastName,
    cellphone, setCellphone,
    email, setEmail,
    emailValidation, setEmailValidation,
    hasDisability, setHasDisability,
    hasConadis, setHasConadis,
    disabilityDescription, setDisabilityDescription,
    conadisDocument, setConadisDocument } = useContext(PersonalInformationContext);
  const [working, setWorking] = useState(false);
  const [canEdit, setCanEdit] = useState(true);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [invalidName, setInvalidName] = useState(false);
  const [invalidFirstLastName, setInvalidFirstLastName] = useState(false);
  const [invalidSecondLastName, setInvalidSecondLastName] = useState(false);
  const [invalidDisabilityDescription, setInvalidDisabilityDescription] = useState(false);

  const [invalidPhoneFeedback, setInvalidPhoneFeedback] = useState(false);
  const [invalidEmailFeedback, setInvalidEmailFeedback] = useState(false);
  const [invalidEmailValidationFeedback, setInvalidEmailValidationFeedback] = useState(false);
  const [condition, setCondition] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errMessages, setErrMessages] = useState([]);
  const [disclaimersList, setDisclaimersList] = React.useState([]);

  const handleDisabilityChange = (event) => {
    setHasDisability(event.target.value);
  };

  const conditionButton = () => {
    const result = disclaimersList.every(item => item.checked === true) && hasDisability !== '' && name?.length > 0 && firstLastName?.length > 0 && secondLastName?.length > 0 && cellphone?.length >= 9 && email?.length > 0 && emailValidation?.length > 0 && email === emailValidation && (hasDisability === "si" ? disabilityDescription.length : true);
    return result;
  }

  const listDisclaimers = (accepted = false) => {
    APIServices.listDisclaimersTerms()
      .then((response) => {
        const disclaimers = response.data.data?.list;
        const updatedDisclaimers = disclaimers?.map((file) => {
          const { ...fileRest } = file;
          return ({
            ...fileRest,
            checked: accepted,
          })
        })
        setDisclaimersList(updatedDisclaimers);
      })
      .catch((error) => {
        setErrMessages(error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }])
      })
  }


  const evaluateStudent = (e) => {
    if (!canEdit) {
      if (correcting)
        navigate(ROUTES.APPLICATION_SUMMARY);
      else
        navigate(ROUTES.EDUCATION_FIFTH_SCHOOL_TYPE);
    }
    else {
      let valid = true;
      if (!conditionButton) {
        valid = false;
      }
      if (!name.length) {
        setInvalidName(true);
        valid = false;
      }
      if (!firstLastName.length) {
        setInvalidFirstLastName(true);
        valid = false;
      }
      if (!secondLastName.length) {
        setInvalidSecondLastName(true);
        valid = false;
      }
      if (hasDisability === "si" && !disabilityDescription.length) {
        setInvalidDisabilityDescription(true);
        valid = false;
      }

      if (!isValidEmail(email)) {
        setInvalidEmailFeedback(true);
        valid = false;
      }
      if (!isValidEmail(emailValidation)) {
        setInvalidEmailValidationFeedback(true);
        valid = false;
      }
      if (email !== emailValidation) {
        setInvalidEmailValidationFeedback(true);
        valid = false;
      }
      if (cellphone.length < 9) {
        setInvalidPhoneFeedback(true);
        valid = false;
      }

      if (!valid) return;

      const data = {
        "applicationId": applicationID,
        "name": name,
        "firstSurname": firstLastName,
        "secondSurname": secondLastName,
        "cellPhone": cellphone,
        "email": email,
        "hasDisability": hasDisability === "si",
        "hasConadis": hasConadis === "si",
        "conadisDocument": conadisDocument,
        "disabilityDescription": disabilityDescription,
        "declarationAccepted": disclaimersList.every(item => item.checked === true),
      }
      setWorking(true);
      setErrMessages([]);
      APIServices.saveApplicantInfo(data)
        .then((response) => {
          if (verificationCodeValidated) {
            if (correcting)
              navigate(ROUTES.APPLICATION_SUMMARY);
            else
              navigate(ROUTES.EDUCATION_FIFTH_SCHOOL_TYPE);
          }
          else {
            navigate(ROUTES.STUDENT_CHOOSE_VERIFICATION_TYPE, { state: { cellphone: cellphone, email: email } });
          }
        })
        .catch((error) => {
          setErrMessages(error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }])
          const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
          scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
          window.scrollTo({ top: 0, behavior: 'smooth' });
        })
        .finally(() => {
          setWorking(false);
        })
    }
  }

  const checkName = () => {
    if (!name.length) {
      setInvalidName(true);
      return;
    }
  }

  const checkFirstLastName = () => {
    if (!firstLastName.length) {
      setInvalidFirstLastName(true);
      return;
    }
  }

  const checkSecondLastName = () => {
    if (!secondLastName.length) {
      setInvalidSecondLastName(true);
      return;
    }
  }

  const checkDisabilityDescription = () => {
    if (hasDisability === "si" && !disabilityDescription.length) {
      setInvalidDisabilityDescription(true);
      return;
    }
  }

  const checkEmail = () => {
    if (!isValidEmail(email)) {
      setInvalidEmailFeedback(true);
      return;
    }
  }

  const checkEmailValidation = () => {
    if (!isValidEmail(emailValidation)) {
      setInvalidEmailValidationFeedback(true);
    }
    if (email !== emailValidation) {
      setInvalidEmailValidationFeedback(true);
    }
  }

  const checkPhoneValidation = () => {
    if (cellphone.length < 9) {
      setInvalidPhoneFeedback(true);
      return;
    }
  }

  const handleDisclaimerChange = (index) => {
    const newDisclaimersList = [...disclaimersList];
    newDisclaimersList[index].checked = !newDisclaimersList[index].checked;
    setDisclaimersList(newDisclaimersList);
  }

  const handleChangeName = (e) => {
    invalidName && setInvalidName(false);
    setName(e.target.value.toUpperCase())
  }

  const handleChangeFirstLastName = (e) => {
    invalidFirstLastName && setInvalidFirstLastName(false);
    setFirstLastName(e.target.value.toUpperCase())
  }

  const handleChangeSecondLastName = (e) => {
    invalidSecondLastName && setInvalidSecondLastName(false);
    setSecondLastName(e.target.value.toUpperCase())
  }

  const handleChangeCellphone = (e) => {
    invalidPhoneFeedback && setInvalidPhoneFeedback(false);
    if (e.target.value?.length < 50) {
      setCellphone(e.target.value);
    }
  }

  const handleChangeEmail = (e) => {
    invalidEmailFeedback && setInvalidEmailFeedback(false);
    setEmail(e.target.value.toLowerCase());
  }

  const handleChangeEmailValidation = (e) => {
    invalidEmailValidationFeedback && setInvalidEmailValidationFeedback(false);
    setEmailValidation(e.target.value.toLowerCase())
  }

  const handleChangeDisabilityDescription = (value) => {
    invalidDisabilityDescription && setInvalidDisabilityDescription(false);
    setDisabilityDescription(value.toUpperCase())
  }

  const handleChangeHasConadis = (value) => {
    setHasConadis(value)
  }
  const handleChangeConadisDocument = (value) => {
    setConadisDocument(value)
  }

  const preventClipboard = (e) => {
    e.preventDefault();
  }

  const fetchData = async () => {
    if (!applicationID) return;
    setIsLoading(true);
    await APIServices.fetchApplicantInfo(applicationID)
      .then((response) => {
        const data = response.data.data;
        if (data) {
          setName(data.name ?? '');
          setFirstLastName(data.firstSurname ?? '');
          setSecondLastName(data.secondSurname ?? '');
          setCellphone(data.cellPhone ?? '');
          setEmail(data.email ?? '');
          setEmailValidation(data.email ?? '');
          setHasDisability(data.hasDisability !== null ? (data.hasDisability ? "si" : "no") : "");
          setHasConadis(data.hasDisability !== null ? (data.hasConadis ? "si" : "no") : "")
          setConadisDocument(data.conadisDocument ?? '')
          setDisabilityDescription(data.disabilityDescription ?? '');
        }
        const status = response.data.data?.status;
        setCanEdit(canUserEditInformation(status));

        listDisclaimers(data.declarationAccepted ?? false);
      })
      .catch((error) => {
        // No se pudo obtener la info (aún no ha sido registrada)
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  useEffect(() => {
    setCondition(conditionButton())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, name, firstLastName, secondLastName, cellphone, emailValidation, disclaimersList, hasConadis, hasDisability, disabilityDescription, conadisDocument]);

  useEffect(() => {
    const callServices = async () => {
      await fetchData();
    }
    callServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.reRenderLayout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {
        isLoading ?
          <>
            <Loading />
          </> :
          <>
            <Grid container direction="column" spacing={2}>
              {
                errMessages?.length ? <Grid item xs={12}>
                  <Alert role="alert" severity="error">
                    {
                      errMessages.map((err, index) => (
                        <Typography key={index}> {err.code?.length ? `[${err.code}]` : ""} {err.message}</Typography>
                      ))
                    }
                  </Alert>
                </Grid> : <></>
              }
              {
                !canEdit ?
                  <>
                    <Grid item xs={12}>
                      <Alert role="alert" severity="warning">
                        <Typography>{t('common.already-confirmed')}</Typography>
                      </Alert>
                    </Grid>
                  </> : <></>
              }
              <Grid item xs={12}>
                <Typography variant="h5" component="h4" style={{ textDecoration: 'underline', fontWeight: '500' }}>
                  {t('personal-data.title')}
                </Typography>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12}>
                  <TextField disabled={!canEdit} autoComplete='off' onChange={(e) => handleChangeName(e)} onBlur={checkName} value={name} fullWidth label={t('personal-data.names-placeholder')} />
                </Grid>
                {
                  invalidName ?
                    <>
                      <Grid item xs={12}>
                        <div className="disclaimer validation-error"> {t('personal-data.validation-error.name')} </div>
                      </Grid>
                    </> : <></>
                }
                <Grid item xs={12}>
                  <TextField disabled={!canEdit} autoComplete='off' onChange={(e) => handleChangeFirstLastName(e)} onBlur={checkFirstLastName} value={firstLastName} fullWidth label={t('personal-data.first-lastname')} />
                </Grid>
                {
                  invalidFirstLastName ?
                    <>
                      <Grid item xs={12}>
                        <div className="disclaimer validation-error"> {t('personal-data.validation-error.first-lastname')} </div>
                      </Grid>
                    </> : <></>
                }
                <Grid item xs={12}>
                  <TextField disabled={!canEdit} autoComplete='off' onChange={(e) => handleChangeSecondLastName(e)} onBlur={checkSecondLastName} value={secondLastName} fullWidth label={t('personal-data.second-lastname')} />
                </Grid>
                {
                  invalidSecondLastName ?
                    <>
                      <Grid item xs={12}>
                        <div className="disclaimer validation-error"> {t('personal-data.validation-error.second-lastname')} </div>
                      </Grid>
                    </> : <></>
                }
                <Grid item xs={12}>
                  <TextField disabled={!canEdit} autoComplete='off' onChange={(e) => handleChangeCellphone(e)} onBlur={checkPhoneValidation} value={cellphone} fullWidth label={t('personal-data.phone-number')} onCopy={preventClipboard} onKeyDown={handlePreventInvalidPhoneNumbers} onPaste={preventClipboard} />
                </Grid>
                {
                  invalidPhoneFeedback ?
                    <>
                      <Grid item xs={12}>
                        <div className="disclaimer validation-error"> {t('personal-data.validation-error.phone')} </div>
                      </Grid>
                    </> : <></>
                }
                <Grid item xs={12}>
                  <TextField disabled={!canEdit} autoComplete='off' onChange={(e) => handleChangeEmail(e)} onBlur={checkEmail} value={email} fullWidth label={t('personal-data.email')} onCopy={preventClipboard} onPaste={preventClipboard} />
                </Grid>
                {
                  invalidEmailFeedback ?
                    <>
                      <Grid item xs={12}>
                        <div className="disclaimer validation-error"> {t('personal-data.validation-error.email')} </div>
                      </Grid>
                    </> : <></>
                }
                <Grid item xs={12}>
                  <TextField disabled={!canEdit} autoComplete='off' onChange={(e) => handleChangeEmailValidation(e)} onBlur={checkEmailValidation} value={emailValidation} fullWidth label={t('personal-data.email-reenter')} onCopy={preventClipboard} onPaste={preventClipboard} />
                </Grid>
                {
                  invalidEmailValidationFeedback ?
                    <>
                      <Grid item xs={12}>
                        {
                          email.length && emailValidation.length && email !== emailValidation ?
                            <>
                              <div className="disclaimer validation-error"> {t('personal-data.validation-error.email-match')} </div>
                            </> : <>
                              <div className="disclaimer validation-error"> {t('personal-data.validation-error.email')} </div>
                            </>
                        }
                      </Grid>
                    </> : <></>
                }
                <Grid item xs={12}>
                  <div className="disclaimer"> {t('personal-data.email-sub-label')} </div>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" component="h4">
                    {t('personal-data.personal-data-disability.has-disability-label')}
                  </Typography>
                  <FormControl fullWidth>
                    <RadioGroup
                      value={hasDisability}
                    >
                      <Grid container spacing={2} >
                        <Grid item xs={6}>
                          <FormControlLabel
                            value="si"
                            onChange={handleDisabilityChange}
                            disabled={!canEdit}
                            control={<Radio checked={hasDisability === 'si'} />}
                            label={t('education.fifth-year.fifth-year-information.same-institute-options.yes')}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormControlLabel
                            value="no"
                            onChange={handleDisabilityChange}
                            disabled={!canEdit}
                            control={<Radio checked={hasDisability === 'no'} />}
                            label={t('education.fifth-year.fifth-year-information.same-institute-options.no')}
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                  {
                    hasDisability === 'si' ?
                      <Grid item xs={12}>
                        <StudentPersonalDataDisability
                          hasConadis={hasConadis}
                          handleChangeHasConadis={handleChangeHasConadis}
                          conadisDocument={conadisDocument}
                          handleChangeConadisDocument={handleChangeConadisDocument}
                          disabilityDescription={disabilityDescription}
                          handleChangeDisabilityDescription={handleChangeDisabilityDescription}
                          canEdit={canEdit}
                          invalidDisabilityDescription={invalidDisabilityDescription}
                          checkDisabilityDescription={checkDisabilityDescription}
                        ></StudentPersonalDataDisability>
                      </Grid> : <></>
                  }
                </Grid>
                {
                  disclaimersList?.map((disclaimer, index) => (<React.Fragment key={index}>
                    <Grid item xs={12}>
                      <FormControlLabel disabled={!canEdit} control={<Checkbox onChange={() => handleDisclaimerChange(index)} checked={disclaimer.checked} />} label={<Link to={`${ROUTES.DISCLAIMERS_BASE}/${disclaimer.uuid}`} target='_blank' style={{ color: '#ff5117' }}>{disclaimer.name}</Link>} />
                    </Grid>
                  </React.Fragment>))
                }
                <Grid item xs={12} >
                  <ULButton working={working} onClick={evaluateStudent} disabled={!condition}>{t('buttons.continue')}</ULButton>
                </Grid>
                <Grid item xs={12} >
                  <ULButton variant="outlined" onClick={() => navigate(ROUTES.STUDENT_IDENTIFICATION)} goBack={true}>{t('buttons.back')}</ULButton>
                </Grid>
              </Grid>
            </Grid>
          </>
      }
    </>
  )
}

export default StudentPersonalData;